import React from "react";
import { useNavigate } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import styled from "styled-components";
import facebook from "../../assets/images/icons/facebook.png";
import instagram from "../../assets/images/icons/instagram.png";
import twitter from "../../assets/images/icons/twitter.png";
import youtube from "../../assets/images/icons/youtube.png";
import logo from "../../assets/main-logo.png";

const WrapFooter = styled.div`
  .bg-footer {
    background-color: #ff8744;
    padding: 1rem 0px;
  }
  .copyright_text > div > span {
    cursor: pointer;
  }
  .logo {
    width: 166px;
    font: normal normal 900 30px/46px Noto Sans;
  }
  .copyright_text {
    color: white;
    font: normal normal 400 16px/27px Noto Sans;
  }
  a {
    cursor: pointer;
  }
  @media screen and (max-width: 1199px) {
    .logo {
      font: normal normal 900 26px/46px Noto Sans;
    }
    .copyright_text {
      font: normal normal 400 16px/27px Noto Sans;
    }
  }
  @media screen and (max-width: 991px) {
    .logo {
      font: normal normal 900 18px/25px Noto Sans;
    }
    .copyright_text {
      font: normal normal 400 14px/27px Noto Sans;
    }
  }
  @media screen and (max-width: 767px) {
    .copyright_text {
      font: normal normal 400 12px/27px Noto Sans;
    }
  }
`;
export default function Footer() {
  const navigate = useNavigate();
  return (
    <WrapFooter>
      <Container fluid className="bg-footer">
        <Container>
          <Row className="justify-content-between">
            <Col sm={9}>
              <div className="logo">
                <img src={logo} width="100%" height="100%" alt="Website Logo" />
              </div>
              <div className="copyright_text">
                Copyright © 2025 Dharm Prabhavna Technologies LLP. All Rights
                Reserved.{" "}
                <div>
                  <span
                    style={{
                      paddingRight: " 1rem",
                      borderRight: "2px solid #ffffff",
                    }}
                    onClick={() => navigate("/privacy-policy")}
                  >
                    Privacy Policy{" "}
                  </span>
                  <span
                    style={{
                      padding: "0 1rem",
                      borderRight: "2px solid #ffffff",
                    }}
                    onClick={() => navigate("/terms-conditions")}
                  >
                    Terms & conditions
                  </span>
                  <span
                    onClick={() => navigate("/refund-policy")}
                    style={{ paddingLeft: "1rem" }}
                  >
                    Cancellation & Refund Policy
                  </span>
                </div>
              </div>
            </Col>
            <Col md={2} sm={3} className="align-self-center">
              <div className="d-flex justify-content-sm-between justify-content-evenly">
                <a
                  href="https://www.facebook.com/apnadharmofficial"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={facebook}
                    width="20"
                    height="20"
                    className="cursor-pointer"
                    alt="Facebook"
                  />
                </a>
                <a
                  href="https://twitter.com/apnadharm_X"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={twitter}
                    width="20"
                    height="20"
                    className="cursor-pointer"
                    alt="Twitter"
                  />
                </a>
                <a
                  href="https://www.instagram.com/apnadharmofficial"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={instagram}
                    width="20"
                    height="20"
                    className="cursor-pointer"
                    alt="Instagram"
                  />
                </a>
                <a
                  href="https://www.youtube.com/@apnadharmofficial"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={youtube}
                    width="20"
                    height="20"
                    className="cursor-pointer"
                    alt="Instagram"
                  />
                </a>
              </div>
              <div></div>
            </Col>
          </Row>
        </Container>
      </Container>
    </WrapFooter>
  );
}
