import { useQuery } from "@tanstack/react-query";
import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import styled from "styled-components";
import { getAllTrustType } from "../api/registrationApi";

const WrapperRegistration = styled.div`
  .details_title {
    color: var(--unnamed-color-583703);
    text-align: center;
    margin-bottom: 1rem;
    font: normal normal 900 42px/57px Noto Sans;
    letter-spacing: 0px;
    color: #583703;
    padding-top: 10px;
  }
  .bg_sub_title {
    color: var(--unnamed-color-583703);
    text-align: center;
    font: normal normal 600 20px/30px Noto Sans;
    letter-spacing: 0px;
    color: #583703;
  }
  .bg_size {
    margin-top: 4rem;
    margin-bottom: 3rem;
  }
  .service_box {
    background: #f8f8f8;
    box-shadow: 0px 0px 20px #00000033;
    border-radius: 20px;
    border: none;
    height: 300px;
    cursor: pointer;
  }
  .service_box:hover {
    background-color: #ff8744;
  }
  .service_box:hover .service-as {
    color: #f8f8f8;
  }
  .service_box:hover .service-temp {
    color: #f8f8f8;
  }
  .service_box .card-body {
    display: flex;
    flex-direction: column;
    justify-content: top;
    padding: 25px;
  }
  .services-as {
    color: var(--unnamed-color-583703);
    text-align: center;
    font: normal normal 16px/20px Noto Sans;
    letter-spacing: 0px;
    color: #583703;
    margin-top: 10px;
  }
  .service-temp {
    color: var(--unnamed-color-ff8744);
    text-align: center;
    font: normal normal 900 26px/30px Noto Sans;
    letter-spacing: 0px;
    color: #ff8744;
  }
  a {
    text-decoration: none;
  }
  @media screen and (max-width: 991px) {
    .bg_title {
      font: normal normal 900 48px/60px Noto Sans;
    }
    .service-as {
      font: normal normal bold 18px/35px Noto Sans;
    }
    .service-temp {
      font: normal normal 900 18px/30px Noto Sans;
    }
    .service_box {
      height: 300px;
    }
  }
  @media screen and (max-width: 767px) {
    .bg_sub_title {
      font: normal normal 500 16px/23px Noto Sans;
    }
    .details_title {
      font: normal normal 900 35px/57px Noto Sans;
    }
    .bg_size {
      margin-top: 2rem;
      margin-bottom: 3rem;
    }
    .service_box {
      height: 300px;
    }
    .service-as {
      font: normal normal bold 16px/24px Noto Sans;
    }
    .service-temp {
      font: normal normal 900 18px/25px Noto Sans;
    }
  }
  @media screen and (max-width: 540px) {
    .bg_sub_title {
      font: normal normal 500 15px/23px Noto Sans;
    }
    .details_title {
      font: normal normal 900 30px/60px Noto Sans;
    }
  }
  @media screen and (max-width: 494px) {
    .bg_sub_title {
      font: normal normal 500 14px/23px Noto Sans;
    }
    .details_title {
      font: normal normal 900 24px/60px Noto Sans;
    }
  }
  @media screen and (max-width: 463px) {
    .bg_sub_title {
      font: normal normal 500 12px/16px Noto Sans;
    }
    .details_title {
      font: normal normal 900 22px/40px Noto Sans;
    }
  }
  @media screen and (max-width: 377px) {
    .service-as {
      font: normal normal bold 12px/18px Noto Sans;
    }
    .service-temp {
      font: normal normal 900 16px/25px Noto Sans;
    }
  }
`;

export default function ProductServices() {
  // trust Types
  const loadTrustType = useQuery(["trustTypes"], () => getAllTrustType());

  //const trustType = useMemo(
  //  () => loadTrustType?.data?.results ?? [],
  //  [loadTrustType]
  //);

  function ConvertFirstLatterToCapital(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  const trustType = [
    {
      name: "Trust Website",
      description: `Customized website development for temples, Gaushalas, and Aushadhalaya.
      Responsive design for mobile and desktop.
      User-friendly interface.
      Integration of essential features like digital noticeboard, facility listing, etc.`,
    },
    {
      name: "Finance Management",
      description: `Comprehensive finance management system tailored for religious institutions.
      Automated tracking of income, expenses, donations, and grants.
      Generation of detailed financial reports.
      Compliance with accounting standards and regulations.`,
    },
    {
      name: "Donor Database",
      description: `Centralised donor database for compliance purpose
      Tracking of donor contributions, pledges, and contact information.`,
    },
    {
      name: "Digital Payment Collections",
      description: `Integration of secure payment gateways for digital donations.
      Acceptance of payments via credit/debit cards, net banking, UPI, etc.
      Automated receipts and acknowledgments for donors.`,
    },
    {
      name: "Asset and Inventory Management",
      description: `Tracking of temple assets & perishable inventory.
      Inventory management for perishable and non-perishable items.
      Monitoring of inventory utilization.`,
    },
    {
      name: "News and Event Broadcast",
      description: `Broadcasting of news, events, and announcements to subscribed users.
      Personalized notifications for upcoming events and special occasions.`,
    },
    {
      name: "Digital Noticeboard",
      description: `Publish Important updates and notices.
      Customizable templates for different types of announcements.
      Real-time updates and scheduling of notices.`,
    },
    {
      name: "Gaushala Management",
      description: `Comprehensive management system for pashudhan in Gaushalas.
      Tracking of pashu's health, breeding, and Medical records.
      Complete tracking of perishable inventory such as grass, pashu's feed etc.`,
    },
    {
      name: "Data Security and Compliance",
      description: `Implementation of robust security measures to protect sensitive data.
      Regular audits and assessments to ensure adherence to security standards.`,
    },
  ];

  return (
    <WrapperRegistration>
      <Container fluid style={{ paddingTop: "1rem" }}>
        <Container className="bg_size">
          <div className="text-center">
            <div className="details_title">Product & Services</div>
            {/*<div className="bg_sub_title pb-4">
              All Temples and religious trusts can contact us by filling the
              following form to activate their account. Individual Users can
              register by downloading app from iOS or google play store.
  </div>*/}
          </div>
          <Row className="row-cols-4">
            {trustType?.map((item, index) => {
              return (
                <Col
                  md={4}
                  sm={6}
                  xs={12}
                  key={index}
                  className="mt-4 col-12"
                  onClick={() => {}}
                >
                  <Card className="service_box">
                    <CardBody>
                      <div className="service-temp">{item?.name}</div>
                      <div className="services-as">
                        {item?.description.split("\\").map((line, index) => (
                          <p key={index}>{line}</p>
                        ))}
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              );
            })}
          </Row>
        </Container>
      </Container>
    </WrapperRegistration>
  );
}
